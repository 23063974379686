import { config } from 'core/config';
import { browserStorage } from 'state/helpers/browserStorage';

export const startMockServiceWorker = async () => {
  if (config.environment === 'development') {
    // Add a function to the window object to change the browser storage key
    const enableMsw = (enable: boolean) => {
      browserStorage.setItem('enableMsw', enable ? 'true' : 'false');
      window.location.reload();
    };
    window.enableMsw = enableMsw;

    // Check the current value of the browser storage key
    if (browserStorage.getItem('enableMsw') === 'true' || window.Cypress) {
      return import('./browser').then(({ worker }) => {
        window.appReady = true;
        return worker.start({
          onUnhandledRequest(req) {
            if (
              req.url.hostname === 'api-js.mixpanel.com' ||
              req.url.hostname === 'fonts.gstatic.com' ||
              req.url.hostname === 'fonts.googleapis.com' ||
              req.url.hostname === 'maps.googleapis.com' ||
              req.url.hostname === 'maps.gstatic.com' ||
              req.url.pathname.startsWith('/static/js/') ||
              req.url.pathname.startsWith('/static/media/') ||
              req.url.pathname.startsWith('/cardlaySapconcur/') ||
              req.url.pathname.startsWith('/lunadis/') ||
              req.url.pathname.startsWith('/pdfjs')
            ) {
              return;
            }

            // eslint-disable-next-line no-console
            console.warn(
              'MSW found an unhandled %s request to %s',
              req.method,
              req.url.href
            );
          },
        });
      });
    }

    // Set the browser storage key to false as default
    browserStorage.setItem('enableMsw', 'false');
  }

  return Promise.resolve();
};
