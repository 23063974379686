import { tr as internationalizationTr } from '@cardlay/i18n';
import { config } from 'core/config';

export { setLocalization } from '@cardlay/i18n';

export const tr = (
  id: string,
  { values = {}, useDefaultLocale = false } = {}
) => {
  const shouldReply =
    config.environment === 'development' &&
    localStorage.getItem('debug-translations') === 'true';

  return shouldReply
    ? id
    : internationalizationTr(id, { values, useDefaultLocale });
};
