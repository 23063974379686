import { Environment } from './environmentVariables';
import { DomainSolutions, Solution } from './solutionConfiguration';

// Type for app config that we get from the API
export type AppConfigResponse = {
  CONFIG_AG_GRID_LICENSE: string;
  CONFIG_API_ENDPOINT: string;
  CONFIG_CONSOLIDATED_API_ENDPOINT: string;
  CONFIG_APP_NAME: string;
  CONFIG_CARDLAY_CLIENT_ID: string;
  CONFIG_CONCUR_CLIENT_ID: string;
  CONFIG_CONCUR_OAUTH_API_URL: string;
  CONFIG_LOGGING_DEBUG_ENABLED: string;
  CONFIG_LOGGING_ENABLED: string;
  CONFIG_MIXPANEL_TOKEN: string;
  CONFIG_SENTRY_DSN: string;
  CONFIG_VERSION: string;
  CONFIG_SEON_SDK_HOST: string;
  CONFIG_SEON_SDK_SOURCE_URL: string;
  CONFIG_SEON_SDK_SCRIPT_ID: string;
  CONFIG_SOLUTION: DomainSolutions;
  CONFIG_GOOGLE_MAPS_API_KEY: string;
};

// Type for combined (and parsed) app config
export type AppConfig = {
  // Config from the API
  agGridLicense: string;
  apiBaseURL: string;
  consolidatedApiBaseURL: string;
  appName: string;
  cardlayClientId: string;
  concurClientId: string;
  loggingDebugEnabled: boolean;
  loggingEnabled: boolean;
  mixpanelToken: string;
  concurOauthBaseURL: string;
  sentryDsn: string;
  version: string;

  // Local app config
  seonSDKHost: string;
  seonSDKSourceURL: string;
  seonSDKScriptId: string;
  environment: Environment;
  solution: Solution;
  googleMapsApiKey: string;
};

export const config: AppConfig = {
  agGridLicense: '',
  apiBaseURL: '',
  consolidatedApiBaseURL: '',
  appName: '',
  cardlayClientId: '',
  concurClientId: '',
  concurOauthBaseURL: '',
  loggingDebugEnabled: false,
  loggingEnabled: false,
  mixpanelToken: '',
  sentryDsn: '',
  version: '',
  seonSDKHost: '',
  seonSDKSourceURL: '',
  seonSDKScriptId: '',
  environment: 'production' as Environment,
  solution: 'cardlaySapconcur',
  googleMapsApiKey: '',
};
