export const browserStorage = {
  getItem: (key: string) => localStorage.getItem(key),

  setItem: (key: string, value: string) => localStorage.setItem(key, value),

  removeItem: (key: string) => localStorage.removeItem(key),

  clear: () => localStorage.clear(),

  getAll: () =>
    Object.keys(localStorage).reduce(
      (acc, key) => ({ ...acc, [key]: localStorage.getItem(key) }),
      {}
    ),
};

export const sessionBrowserStorage = {
  getItem: (key: string) => sessionStorage.getItem(key),

  setItem: (key: string, value: string) => sessionStorage.setItem(key, value),

  removeItem: (key: string) => sessionStorage.removeItem(key),

  clear: () => sessionStorage.clear(),

  getAll: () =>
    Object.keys(sessionStorage).reduce(
      (acc, key) => ({ ...acc, [key]: sessionStorage.getItem(key) }),
      {}
    ),
};
