import { loadAppConfiguration } from 'core/config';
import { startMockServiceWorker } from 'mockApi/startMockServiceWorker';

const start = async (): Promise<void> => {
  // It is important that we load the configuration before we do anything else.
  // The config is populated with values from env variables (or from /config in
  // production). So we need that to complete before we import the bootstrap
  // module and bootstraps the app
  await loadAppConfiguration();

  // Start the mock service worker (if we're in a development environment)
  await startMockServiceWorker();

  const { bootstrap } = await import(
    /* webpackChunkName: "bootstrap" */ 'bootstrap'
  );

  await bootstrap();
};

void start();
