import { AppConfig, config } from './config';
import {
  parseEnvVarsToConfigProps,
  getEnvironmentVariablesFromAPI,
  getEnvironmentVariablesFromLocal,
} from './environmentVariables';

export const loadAppConfiguration = async (): Promise<AppConfig> => {
  const environmentVariables =
    process.env.NODE_ENV === 'production'
      ? await getEnvironmentVariablesFromAPI()
      : await getEnvironmentVariablesFromLocal();

  const configPropsFromEnvVars =
    parseEnvVarsToConfigProps(environmentVariables);

  Object.entries(configPropsFromEnvVars).forEach(([key, val]) => {
    Object.assign(config, { [key]: val });
  });

  return config;
};
